body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    scroll-behavior: smooth;
    height: 100%;
    background-color: #f5f5f5;
    cursor: default; /* 기본 커서를 설정 */
}

.portfolio-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.portfolio-section {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    border-top: 2px solid black;  /* 상단에 검정색 줄 추가 */
    border-bottom: 2px solid black;  /* 하단에 검정색 줄 추가 */
}

.portfolio-image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.portfolio-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* 이미지가 컨테이너에 맞추어지도록 설정 */
    border-radius: 0;
    box-shadow: none;
}

.bottom-center-button {
    position: absolute;
    bottom: 20px; /* 최하단에 배치 */
    left: 50%;
    transform: translateX(-50%); /* 중앙 정렬 */
    padding: 10px 20px;
    font-size: 16px;
    color: blue;
    background-color: transparent;
    border: 2px solid blue;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s, color 0.3s;
}

.bottom-center-button:hover {
    background-color: black;
    color: white;
}

/* 로딩화면 */
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
}

/* Modal styling */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    z-index: 10000;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 500px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    color: #333;
}

.modal-content input[type="number"],
.modal-content input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.modal-content input[type="number"]:focus,
.modal-content input[type="email"]:focus {
    border-color: #3498db;
    outline: none;
}

.modal-content button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
}

.modal-content button:first-of-type {
    background-color: #3498db;
    color: white;
    transition: background-color 0.3s;
}

.modal-content button:first-of-type:hover {
    background-color: #2980b9;
}

.modal-content button:last-of-type {
    background-color: #ccc;
    color: black;
}

.modal-content button:last-of-type:hover {
    background-color: #bbb;
}

.modal-text {
    width: 100%;
    text-align: left;
    white-space: pre-line; /* 줄바꿈을 허용 */
    text-indent: 0; /* 들여쓰기 제거 */
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}
