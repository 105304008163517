* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px; /* 네비게이션 바의 너비 */
  height: 100%; /* 화면 전체 높이 */
  background-color: #f5f5f5; /* 매우 짙은 회색 배경 */
  display: flex;
  flex-direction: column; /* 항목들을 세로로 배치 */
  align-items: center;
  padding: 0;
  z-index: 1000; /* 네비게이션 바가 다른 콘텐츠 위에 오도록 설정 */
  border-right: 2px solid black; /* 네비게이션 바의 오른쪽 경계선 */
}

.navbar ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 항목 간 간격을 균등하게 설정 */
  align-items: center;
}

.navbar ul li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* 각 항목이 동일한 비율로 공간을 차지하도록 설정 */
  border-bottom: 2px solid black; /* 각 항목의 아래쪽에 검정색 선 추가 */
}

.navbar ul li:last-child {
  border-bottom: none; /* 마지막 항목의 아래쪽 경계선 제거 */
}

.navbar ul li a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  writing-mode: vertical-rl; /* 글자를 세로로 배치 */
  transform: rotate(180deg); /* 글자의 위아래 방향을 조정 */
  transition: color 0.3s;
}

.navbar ul li a:hover {
  color: #3498db; /* 마우스 오버 시 글자색만 하늘색으로 변경 */
}

.navbar ul li.active a {
  color: #3498db; /* 활성화된 페이지의 텍스트 색상 변경 */
}
