/* 기본 설정 */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
  cursor: default; /* 기본 커서를 설정 */
}

.home-content {
  padding: 0;
  border: none;
  background-color: #f5f5f5;
}

.main-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section {
  background-color: transparent;
  color: #f5f5f5;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
}

.link-section {
  display: flex;
  justify-content: space-between;
  width: 37%; /* 포트폴리오와 이력서 링크의 전체 너비를 설정 */
}

.portfolio-link, .resume-link {
  background-color: transparent;
  color: #f5f5f5;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  border: 2px solid #f5f5f5; /* 경계선을 흰색으로 설정 */
}

.portfolio-link a, .resume-link a {
  color: #f5f5f5;
  text-decoration: none;
}

.portfolio-link a:hover, .resume-link a:hover {
  color: #3498db;
}

.portfolio-link {
  margin-right: 10px;
}

.resume-link {
  margin-left: 10px;
}
