/* src/index.css 또는 src/App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  scroll-behavior: smooth;
  height: 100%;
}

#root {
  display: flex;
}

.main-content {
  margin-left: 60px; /* 네비게이션 바의 너비에 맞춰 조정 */
  flex-grow: 1;
}
